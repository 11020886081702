import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'antd/dist/antd.css';
import { Button, Carousel } from 'antd';
import 'remixicon/fonts/remixicon.css';
import '../src/vendor/bootstrap/css/bootstrap.min.css';
import '../src/vendor/bootstrap-icons/bootstrap-icons.css';
import '../src/css/style.css';
import {
  RightOutlined,
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  GooglePlusOutlined,
  LinkedinOutlined,
} from '@ant-design/icons';
import axios from 'axios';

function Home() {
  const [parcelData, setParcelData] = useState(null);
  const [tracking_num, setTrackingNum] = useState('');
  const [tracking, setTracking] = useState('');
  const [tracking_status_show, setTrackingStatusShow] = useState(false);
  const [mission_vision, setMissionVision] = useState(true);
  const [core_value, setCoreValue] = useState(false);
  const [history, setHistory] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (!parcelData) {
      setTrackingStatusShow(false);
      setShowAlert(false);
    } else {
      setTrackingStatusShow(true);
      if (tracking.startsWith("BPICC") || tracking.startsWith("ROBCC")) {
        setShowAlert(true);
      }
      else {
        setShowAlert(false);
      }
    }
  }, [parcelData,tracking]);

  const handleSubmit = async (event) => {
    try {
      setTracking(tracking_num)
      event.preventDefault()
      const data = await axios.get(
        `https://api.speedworkscourier.com/v2/parcels/tracking?search=${tracking_num}`
      );
      setParcelData(data?.data?.data);
    } catch (error) {
      console.log(error);
      setParcelData([]);
    }
  };

  const previewStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    margin: '0 auto',
    marginTop: 32,
    maxWidth: 250,
  };

  const btnCoreVal = () => {
    setCoreValue(true);
    setMissionVision(false);
    setHistory(false);
  };

  const btnMissionVision = () => {
    setCoreValue(false);
    setMissionVision(true);
    setHistory(false);
  };

  const btnHistory = () => {
    setCoreValue(false);
    setMissionVision(false);
    setHistory(true);
  };

  return (
    <div>
      <header id="header" className="fixed-top d-flex align-items-center">
        <div className="container d-flex align-items-center">
          <a href="/" className="logo me-auto">
            <img src="assets/img/SPEED-Logo.png" alt="" />
          </a>
          <nav id="navbar" className="navbar order-last order-lg-0">
            <ul>
              <li>
                <a className="nav-link scrollto active" href="#hero">
                  Home
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#about">
                  About Us
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#pricing">
                  Tracking
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#contact">
                  Contact Us
                </a>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>
        </div>
      </header>
      <section id="hero" className="d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
              <h1>DOOR TO DOOR DELIVERY</h1>
              <h2>
                We make it our business to support your business. Our team of
                delivery experts are available to help you transport your goods
                on time.
              </h2>
              <a href="#pricing" className="btn-get-started scrollto">
                Get Started
              </a>
            </div>
          </div>
        </div>
      </section>
      <main id="main">
        <section id="clients" className="clients">
          <div className="container">
            <Carousel effect="fade" autoplay>
              <div style={previewStyle}>
                <div className="row">
                  <div className="col-md-2">
                    <img
                      src="assets/img/clients/Picture2.png"
                      style={{
                        filter: 'grayscale(100%)',
                        height: 'auto',
                        maxWidth: '100%',
                        width: '114px',
                      }}
                    />
                  </div>
                  <div className="col-md-2">
                    <img
                      src="assets/img/clients/Picture1.png"
                      style={{
                        filter: 'grayscale(100%)',
                        height: 'auto',
                        maxWidth: '100%',
                        width: '114px',
                      }}
                    />
                  </div>
                  <div className="col-md-2">
                    <img
                      src="assets/img/clients/Picture2.png"
                      style={{
                        filter: 'grayscale(100%)',
                        height: 'auto',
                        maxWidth: '100%',
                        width: '114px',
                      }}
                    />
                  </div>
                  <div className="col-md-2">
                    <img
                      src="assets/img/clients/Picture1.png"
                      style={{
                        filter: 'grayscale(100%)',
                        height: 'auto',
                        maxWidth: '100%',
                        width: '114px',
                      }}
                    />
                  </div>
                  <div className="col-md-2">
                    <img
                      src="assets/img/clients/Picture2.png"
                      style={{
                        filter: 'grayscale(100%)',
                        height: 'auto',
                        maxWidth: '100%',
                        width: '114px',
                      }}
                    />
                  </div>
                  <div className="col-md-2">
                    <img
                      src="assets/img/clients/Picture1.png"
                      style={{
                        filter: 'grayscale(100%)',
                        height: 'auto',
                        maxWidth: '100%',
                        width: '114px',
                      }}
                    />
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </section>
        <section id="about" className="about section-bg">
          <div className="container">
            <div className="row no-gutters">
              <div className="content col-xl-5 d-flex align-items-stretch">
                <div className="content">
                  <h3 style={{ color: 'white' }}>
                    With Door-to-Door Logistics
                  </h3>
                  <p>
                    We guarantee fast, secure and reliable door to door delivery
                    service.
                  </p>
                  <h3 style={{ color: 'white' }}>Cash on Delivery Service</h3>
                  <p>We provide your customers with a COD payment.</p>
                  <a href="#contact" className="about-btn">
                    <span>CONTACT US</span> <RightOutlined />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="tabs" className="tabs">
          <div className="container">
            <ul className="nav nav-tabs row d-flex">
              <li className="nav-item col-3">
                <a
                  className={
                    mission_vision ? 'nav-link active show' : 'nav-link'
                  }
                >
                  <i className="ri-gps-line"></i>
                  <h4
                    className="d-none d-lg-block"
                    onClick={() => {
                      btnMissionVision();
                    }}
                  >
                    Mission & Vision
                  </h4>
                </a>
              </li>
              <li className="nav-item col-3">
                <a className={core_value ? 'nav-link active show' : 'nav-link'}>
                  <i className="ri-body-scan-line"></i>
                  <h4
                    className="d-none d-lg-block"
                    onClick={() => {
                      btnCoreVal();
                    }}
                  >
                    Core Values
                  </h4>
                </a>
              </li>
              <li className="nav-item col-3">
                <a className={history ? 'nav-link active show' : 'nav-link'}>
                  <i className="ri-sun-line"></i>
                  <h4
                    className="d-none d-lg-block"
                    onClick={() => {
                      btnHistory();
                    }}
                  >
                    History
                  </h4>
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane active show" id="tab-1">
                <div className="row">
                  <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                    <h3>
                      {mission_vision
                        ? 'Mission Vision'
                        : core_value
                        ? 'Core Values'
                        : history
                        ? 'History'
                        : ''}
                    </h3>
                    {mission_vision ? (
                      <>
                        <ul>
                          <li>
                            {/* <i className="ri-check-double-line"></i> */}
                            It is our commitment to provide exceptional courier
                            service to our clients with passion and integrity.
                          </li>
                        </ul>
                        <ul>
                          <li>
                            {/* <i className="ri-check-double-line"></i> */}
                            We set the standards to courier perfection.{' '}
                          </li>
                        </ul>
                      </>
                    ) : core_value ? (
                      <>
                        <ul>
                          <li>
                            {/* <i className="ri-check-double-line"></i> */}
                            Go Above and Beyond – We strive to exceed the
                            expectations of the people we work with and the
                            clients we serve. From taking ownership of
                            responsibilities outside of our job scope, to
                            remaining committed to a venture from start to
                            finish.
                          </li>
                        </ul>
                        <ul>
                          <li>
                            {/* <i className="ri-check-double-line"></i> */}
                            Our Team - Our successful teamwork is achieved by
                            holding on to individual strengths and overcoming
                            differences to produce the most favorable results.
                            Working in this way builds trust and respect for
                            each team member and maximizes productivity.
                          </li>
                        </ul>
                        <ul>
                          <li>
                            {/* <i className="ri-check-double-line"></i> */}
                            Trust and Integrity – We exert all our efforts with
                            honesty and integrity at all times. We are
                            transparent and trustworthy with our employees and
                            clients. Setting a good example by knowing and doing
                            what is right inside and outside of the
                            organization.
                          </li>
                        </ul>
                      </>
                    ) : history ? (
                      <>
                        <ul>
                          <li>
                            Speedworks Courier Services Corporation is a private
                            company established in January 1996 by proprietor
                            Marfred G. Samonte with the purpose of providing
                            messengerial service to private and public sectors.
                          </li>
                        </ul>
                        <ul>
                          <li>
                            {/* <i className="ri-check-double-line"></i> */}
                            With an outstanding team committed to perfecting the
                            craft, the company flourished and became a
                            corporation in 2015.
                          </li>
                        </ul>
                        <ul>
                          <li>
                            {/* <i className="ri-check-double-line"></i> */}
                            Today, the company continues to provide top notch
                            quality service to its clients. With its dedicated
                            team and branch locations throughout NCR, Speedworks
                            Courier Services Corporation is your premium service
                            of choice.
                          </li>
                        </ul>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="col-lg-6 order-1 order-lg-2 text-center">
                    <img src="assets/tabs-1.jpg" alt="" className="img-fluid" />
                    <img
                      src="assets/missionvision.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>

              <div className="tab-pane" id="tab-2">
                <div className="row">
                  <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                    <h3>Go Above and Beyond</h3>
                    <br />
                    <p>
                      We strive to exceed the expectations of the people we work
                      with and the clients we serve. From taking ownership of
                      responsibilities outside of our job scope, to remaining
                      committed to a venture from start to finish.
                      <br />
                      <br />
                      We go above and beyond to make a positive impact on what
                      we do.
                    </p>
                    <ul>
                      <li>
                        <i className="ri-check-double-line"></i> Trust and
                        Integrity – We exert all our efforts with honesty and{' '}
                        <br /> integrity at all times.
                      </li>
                      <li>
                        <i className="ri-check-double-line"></i> We are
                        transparent and trustworthy with our employees and
                        clients.
                      </li>
                      <li>
                        <i className="ri-check-double-line"></i> Setting a good
                        example by knowing and doing what is right inside <br />
                        and outside of the organization.
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-6 order-1 order-lg-2 text-center">
                    <img src="assets/tabs-2.jpg" alt="" className="img-fluid" />
                    <img
                      src="assets/corevalues.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>

              <div className="tab-pane" id="tab-3">
                <div className="row">
                  <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
                    <h3>History</h3>
                    <br/><br />
                    <p>Speedworks Courier Services Corporation is a private
                      company established in January 1996 by proprietor Marfred
                      G. Samonte with the purpose of providing messengerial
                      service to private, government and commercial companies.{' '}
                      <br /> <br />
                      With an outstanding team committed to perfecting the
                      craft, the company flourished and became a corporation in
                      2015.
                    </p>
                    <p>
                      Today, the company continues to provide top notch quality
                      service to its clients. With its dedicated team and branch
                      locations throughout NCR, Speedworks Courier Services
                      Corporation is your premium service of choice.
                    </p>
                  </div>
                  <div className="col-lg-6 order-1 order-lg-2 text-center">
                    <img
                      src="assets/history.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="pricing" className="pricing section-bg">
          <div className="container">
            <div className="section-title">
              <h2 style={{ color: 'white' }}>Track parcel</h2>
              <div className="row">
                <div className="col-lg-12 col-md-6 mt-4 mt-md-0">
                  <div className="box featured">
                    {tracking_status_show ? (
                      <>
                        <h3 className="text-light">
                          ORDER NUMBER / TRACKING NUMBER
                        </h3>
                        {showAlert && (
                          <div className="alert bg-white text-dark text-start" role="alert">
                            <strong>Please note:</strong> For credit card deliveries, the bank does not provide us with your contact number. As a result, we are unable to notify you directly about your delivery status.
                            <br /><br />
                            For any inquiries, please contact us at : 
                            <br />
                            <ol class="list-group list-group-numbered text-start">
                              <li class="">Email: info@speedworkscourier.com</li>
                              <li class="">Phone: 8952-7411</li>
                            </ol>
                            <br />
                            Thank you for your understanding.
                          </div>
                        )}
                        <h3 className="text-light font-weight-bold">
                          {parcelData?.[0]?.delivery?.awb_number}
                        </h3>
                        <table className="table table-sm " >
                          <thead className="table-dark">
                            <tr>
                              {parcelData && parcelData?.length > 0 ? (
                                <>
                                  <th scope="col">Delivery Status</th>
                                  <th scope="col">Date</th>
                                </>
                              ) : (
                                <th>Message</th>
                              )}
                            </tr>
                          </thead>
                          {parcelData && parcelData?.length > 0 ? (
                            parcelData.map((res, index) => {
                              return (
                                <tbody key={index} className="table-light">
                                  {res.status_delivery === 2 ? (
                                    <>
                                      <tr>
                                        <th>
                                          Arrived At Hub <br />{' '}
                                          <span style={{ fontSize: '10px' }}>
                                            {res.delivery?.hub?.name}
                                          </span>
                                        </th>
                                        <th>
                                          {moment(
                                            res.date,
                                            'YYYY/MM/DD:mm:ss'
                                          )
                                            .add(8, 'hours')
                                            .format('dddd, MMMM D, YYYY')}
                                        </th>
                                      </tr>
                                    </>
                                  ) : res.status_delivery === 3 ? (
                                    <>
                                      <tr >
                                        <th>
                                          Out for Delivery <br />{' '}
                                          <span style={{ fontSize: '10px' }}>
                                            {res.desc}
                                          </span>
                                        </th>
                                        <th>
                                          {moment(
                                            res.date,
                                            'YYYY/MM/DD:mm:ss'
                                          )
                                            .add(8, 'hours')
                                            .format('dddd, MMMM D, YYYY')}
                                        </th>
                                      </tr>
                                    </>
                                  ) : res.status_delivery === 4 ? (
                                    <>
                                      <tr className="table-success">
                                        <th>
                                          Delivered <br />{' '}
                                          <span style={{ fontSize: '10px' }}>
                                            {res.desc}
                                          </span>
                                        </th>
                                        <th>
                                          {moment(
                                            res.date,
                                            'YYYY/MM/DD:mm:ss'
                                          )
                                            .add(8, 'hours')
                                            .format('dddd, MMMM D, YYYY')}
                                        </th>
                                      </tr>
                                    </>
                                  ) : res.status_delivery === 5 ? (
                                    <>
                                      <tr className="table-danger">
                                        <th>
                                          Delivery Failed <br />{' '}
                                          <span style={{ fontSize: '10px' }}>
                                            {res?.reason?.name}
                                          </span>
                                        </th>
                                        <th>
                                          {moment(
                                            res.date,
                                            'YYYY/MM/DD:mm:ss'
                                          )
                                            .add(8, 'hours')
                                            .format('dddd, MMMM D YYYY')}
                                        </th>
                                      </tr>
                                    </>
                                  ) : res.status_delivery === 6 ? (
                                    <>
                                      <tr >
                                        <th>
                                          Return To Sender <br />{' '}
                                          <span style={{ fontSize: '10px' }}>
                                            {res.desc}
                                          </span>
                                        </th>
                                        <th>
                                          {moment(
                                            res.date,
                                            'YYYY/MM/DD:mm:ss'
                                          )
                                            .add(8, 'hours')
                                            .format('dddd, MMMM D, YYYY')}
                                        </th>
                                      </tr>
                                    </>
                                  ) : res.status_delivery === 7 ? (
                                    <>
                                      <tr >
                                        <th>
                                          Arrived At Warehouse <br />{' '}
                                          <span style={{ fontSize: '10px' }}>
                                            {res.desc}
                                          </span>
                                        </th>
                                        <th>
                                          {moment(
                                            res.date,
                                            'YYYY/MM/DD:mm:ss'
                                          )
                                            .add(8, 'hours')
                                            .format('dddd, MMMM D, YYYY')}
                                        </th>
                                      </tr>
                                    </>
                                  ) : res.status_delivery === 8 ? (
                                    <>
                                      <tr >
                                        <th>
                                          Intransit to Hub <br />{' '}
                                          <span style={{ fontSize: '10px' }}>
                                            {res.desc}
                                          </span>
                                        </th>
                                        <th>
                                          {moment(
                                            res.date,
                                            'YYYY/MM/DD:mm:ss'
                                          )
                                            .add(8, 'hours')
                                            .format('dddd, MMMM D, YYYY')}
                                        </th>
                                      </tr>
                                    </>
                                  ) : null}
                                </tbody>
                              );
                            })
                          ) : (
                            <tbody>
                              <tr >
                                <th>No Record Found</th>
                              </tr>
                            </tbody>
                          )}
                        </table>
                        <Button
                          onClick={() => setTrackingStatusShow(false)}
                          style={{
                            color: 'white',
                            background: '#1c1c1c',
                            borderColor: '#1c1c1c',
                          }}
                        >
                          Close
                        </Button>
                      </>
                    ) : (
                      <>
                        <h3>Enter Order number / SPD</h3>
                        <div
                          id="tracking"
                          className="d-inline-block"
                          style={{ width: '350px' }}
                        >
                          <form onSubmit={handleSubmit}>
                            <input
                              type="text"
                              name="tracking_num"
                              style={{ color: 'black' }}
                              value={tracking_num}
                              onChange={(e) => setTrackingNum(e.target.value)}
                            />
                            <input type="submit" value="Submit" />
                          </form>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="faq" className="faq">
          <div className="container">
            <div className="section-title">
              <h2>AREA OF COVERAGE</h2>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-md-6">
                    <div className="list-group">
                      <span
                        className="list-group-item"
                        style={{ backgroundColor: '#e03a3c' }}
                      >
                        <b>Metro Manila</b>
                      </span>
                      <span className="list-group-item list-group-item-action">
                          Caloocan
                      </span>
                      <span className="list-group-item list-group-item-action">
                          Las Piñas
                      </span>
                      <span className="list-group-item list-group-item-action">
                          Malabon
                      </span>
                      <span className="list-group-item list-group-item-action">
                          Mandaluyong
                      </span>
                      <span className="list-group-item list-group-item-action">
                          Manila
                      </span>
                      <span className="list-group-item list-group-item-action">
                          Muntinlupa
                      </span>
                      <span className="list-group-item list-group-item-action">
                          Navotas
                      </span>
                      <span className="list-group-item list-group-item-action">
                          Novaliches
                      </span>
                      <span className="list-group-item list-group-item-action">
                          Paranaque
                      </span>
                      <span className="list-group-item list-group-item-action">
                          Pateros
                      </span>
                      <span className="list-group-item list-group-item-action">
                          Quezon City
                      </span>
                      <span className="list-group-item list-group-item-action">
                          Taguig
                      </span>
                      <span className="list-group-item list-group-item-action">
                          Valenzuela
                      </span>

                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="list-group">
                      <span
                        className="list-group-item"
                        style={{ backgroundColor: '#e03a3c' }}
                      >
                        <b>Provincial</b>
                      </span>
                      <span className="list-group-item list-group-item-action">
                        Batangas
                      </span>
                      <span className="list-group-item list-group-item-action">
                        Bulacan
                      </span>
                      <span className="list-group-item list-group-item-action">
                        Cavite
                      </span>
                      <span className="list-group-item list-group-item-action">
                        Laguna
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <img
                  src="assets/branches.png"
                  className="img-fluid"
                  alt=""
                  style={{ maxWidth: '100%' }}
                />
              </div>
            </div>
          </div>
        </section>
        <section id="contact" className="contact">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>CONTACT US</h2>
              <p>
                Let us help you achieve your business goal as a Delivery
                Partner.
              </p>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-md-12">
                    <div className="info-box">
                      <i className="ri-map-pin-fill"></i>
                      <h3>Main Office</h3>
                      <p>
                        SMS Bldg, #15 Jade Street Pilar Village, Las Piñas City
                        1740
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="info-box mt-4">
                      <i className="ri-mail-fill"></i>

                      <h3>Email Us</h3>
                      <p>info@speedworkscourier.com</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="info-box mt-4">
                      <i className="ri-phone-fill"></i>
                      <h3>Call Us</h3>
                      <p>(02) 8 9527411</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7728.138377432466!2d121.006938!3d14.423175!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x54847bf84f91df31!2sSpeedworks%20Courier%20Services%20Corporation!5e0!3m2!1sen!2sph!4v1627284221901!5m2!1sen!2sph"
                  width="600"
                  height="450"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 footer-contact">
                <h3 style={{ color: 'white' }}>
                  Speedworks Courier Services Corporation<span></span>
                </h3>
                <p>
                  SMS Bldg, <br />
                  #15 Jade Street, Pilar Village,
                  <br />
                  Las Piñas City 1740 <br />
                  <br />
                  <strong>Phone:</strong> (+632) 8952-7411
                  <br />
                  <strong>Email:</strong> info@speedworkscourier.com
                  <br />
                </p>
              </div>

              <div className="col-lg-2 col-md-6 footer-links">
                <h4 style={{ color: 'white' }}>Useful Links</h4>
                <ul>
                  <li>
                    <RightOutlined /> <a href="#">Home</a>
                  </li>
                  <li>
                    <RightOutlined /> <a href="#">About us</a>
                  </li>
                  <li>
                    <RightOutlined /> <a href="#">Tracking</a>
                  </li>
                  <li>
                    <RightOutlined /> <a href="#">Terms of Service</a>
                  </li>
                  <li>
                    <RightOutlined />{' '}
                    <a href="/privacy-policy">Privacy Policy</a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                <h4 style={{ color: 'white' }}>Our Services</h4>
                <ul>
                  <li>
                    <RightOutlined />{' '}
                    <a href="#about">Door to Door Delivery Service</a>
                  </li>
                  <li>
                    <RightOutlined /> <a href="#about">COD Service</a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 footer-links">
                <img src="/assets/img/dpo.png" width={200} height={200} />
              </div>
            </div>
          </div>
        </div>

        <div className="container d-md-flex py-4">
          <div className="me-md-auto text-center text-md-start">
            <div className="copyright">
              &copy; Copyright . All Rights Reserved
            </div>
          </div>
          <div className="social-links text-center text-md-end pt-3 pt-md-0">
            <a href="#" className="twitter">
              <TwitterOutlined />
            </a>
            <a href="#" className="facebook">
              <FacebookOutlined />
            </a>
            <a href="#" className="instagram">
              <InstagramOutlined />
            </a>
            <a href="#" className="google-plus">
              <GooglePlusOutlined />
            </a>
            <a href="#" className="linkedin">
              <LinkedinOutlined />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Home;
